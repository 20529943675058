var angular = require('angular');
require('../../services/tags');
require('../../services/tag-categories');

angular.module('dcRecipes').component('tagsList', {
	templateUrl: '/templates/tags/list.html',
	controller: function (Tags, TagCategories, _) {
		var $ctrl = this;

		$ctrl.categories = [];

		TagCategories.getAll().then(function (categories) {
			$ctrl.categories = categories;
		});
	}
});
