require('angular');
require('../../services/users');

angular.module('dcRecipes').component('loginForgotPassword', {
    templateUrl: '/templates/login/forgot-password.html',
    controller: function (Users, ngToast) {
        var ctrl = this;

        ctrl.email = null;

        ctrl.submit = function () {
            if (ctrl.email) {
                Users.requestNewPass(ctrl.email).then(function (res) {
                    ngToast.danger('Du vil modtage en e-mail inden længe med yderligere informationer');
                }, function () {
                    ngToast.danger('Der skete en fejl');
                });
            } else {
                ngToast.danger('Husk at udfyld en e-mail');
            }
        }
    }
});