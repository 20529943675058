var angular = require('angular');
require('../modal');
require('../../services/tags');
require('../../services/tag-categories');

angular.module('dcRecipes').component('tagsNew', {
	templateUrl: '/templates/tags/new.html',
	controller: function (Tags, _) {
  }
});
