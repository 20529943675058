var angular = require('angular');
require('../../services/recipes');
require('../../services/cache.js');

angular.module('dcRecipes').component('recipesList', {
	templateUrl: '/templates/recipes/list.html',
	controller: function (Recipes, AbstractProducts, _, Cache, $window, $q, $timeout) {
		var $ctrl = this;
		var cachedParams = Cache.recipeSearch.getParams();

		$ctrl.page = cachedParams.page ? cachedParams.page : 1;
		$ctrl.targetPage = $ctrl.page;
		$ctrl.query = cachedParams.query ? cachedParams.query : '';
		$ctrl.selectedProduct = cachedParams.selectedProduct ? cachedParams.selectedProduct : null;
		$ctrl.selectedSubrecipe = cachedParams.selectedSubrecipe ? cachedParams.selectedSubrecipe : null;
		$ctrl.sortField = cachedParams.sortField ? cachedParams.sortField : null;
		$ctrl.sortAscending = cachedParams.sortAscending ? true : false;
		$ctrl.pageSize = 20;

		$ctrl.recipes = [];
		$ctrl.totalResults = 0;

		$ctrl.abstractProducts = [];
		$ctrl.subrecipes = [];

		$ctrl.sortFields = [
			{ value: null, name: 'Relevans' },
			{ value: 'created', name: 'Oprettet' },
			{ value: 'updated', name: 'Opdateret' }
		];

		$ctrl.sortDirections = [
			{ value: true, name: 'Stigende' },
			{ value: false, name: 'Faldende' }
		];

		$ctrl.nextPage = function() {
			$window.scrollTo(0, 0);
			Cache.recipeSearch.setScrollHeight($window.scrollY);
			$ctrl.page++;
			$ctrl.search();
		};

		$ctrl.prevPage= function() {
			$window.scrollTo(0, 0);
			Cache.recipeSearch.setScrollHeight($window.scrollY);
			$ctrl.page--;
			$ctrl.search();
		};

		$ctrl.onSubmitSearch = function() {
			$window.scrollTo(0, 0);
			Cache.recipeSearch.setScrollHeight($window.scrollY);
			$ctrl.page = 1;
			$ctrl.search();
		};

		$ctrl.totalPages = function() {
			return Math.ceil($ctrl.totalResults / $ctrl.pageSize);
		};

		$ctrl.changeFilter = function() {
			$ctrl.page = 1;
			$ctrl.search();
		};

		$ctrl.search = function() {
			return Recipes.query($ctrl.query, $ctrl.selectedProduct, $ctrl.selectedSubrecipe, $ctrl.page, $ctrl.pageSize, $ctrl.sortField, $ctrl.sortAscending).then(function(searchResult) {
				Cache.recipeSearch.setParams($ctrl.query, $ctrl.selectedProduct, $ctrl.selectedSubrecipe, $ctrl.page, $ctrl.sortField, $ctrl.sortAscending);
				$ctrl.recipes = _.map(searchResult.results, function(result) {
					return result.document;
				});
				$ctrl.totalResults = searchResult.totalResults;
				$ctrl.updateTargetPage();
			}, function() {
				$ctrl.recipes = [];
			});
		};

		$ctrl.$routerOnActivate = function() {
			AbstractProducts.query('', 1, 10000).then(function(searchResult) {
				$ctrl.abstractProducts = _.map(searchResult.results, function(result) {
					return result.document;
				});
			});

			Recipes.getSubrecipes(1,10000).then(function(subrecipes) {
				$ctrl.subrecipes = subrecipes;
			});

			$ctrl.search().then(function() {
				$timeout(function () {
	          $window.scrollTo(0, Cache.recipeSearch.getScrollHeight());
	      }, 250);
			});
		};

		$ctrl.$routerOnDeactivate = function() {
			Cache.recipeSearch.setScrollHeight($window.scrollY);
		};

		$ctrl.updateTargetPage = function () {
		    $ctrl.targetPage = $ctrl.page;
		}

		$ctrl.updatePage = function () {
		    $ctrl.page = $ctrl.targetPage > $ctrl.totalPages() ? $ctrl.totalPages() : $ctrl.targetPage;
		    $ctrl.search();
		}
	}
});
