var angular = require('angular');
require('./api');

angular.module('dcRecipes').factory('Tags', function ($q, Api) {
	return {
		getByCategoryId: function(id) {
			var deferred = $q.defer();
			Api.tags.getByCategoryId(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		getAll: function () {
			var deferred = $q.defer();
			Api.tags.getAll().then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		update: function (tag) {
			var deferred = $q.defer();
			Api.tags.update(tag).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		create: function (tag) {
			var deferred = $q.defer();
			Api.tags.create(tag).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		delete: function(id) {
			var deferred = $q.defer();
			Api.tags.delete(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		}
	};
});
