require('angular');
require('../../services/users');

angular.module('dcRecipes').component('loginResetPassword', {
    templateUrl: '/templates/login/reset-password.html',
    controller: function (Users, ngToast, $rootRouter, $location) {
        var ctrl = this;

        ctrl.password = null;
        ctrl.token = $location.search().rkt;

        ctrl.submit = function () {
            if (ctrl.password && ctrl.token) {
                Users.resetPassword(ctrl.password, ctrl.token).then(function (res) {
                    ngToast.danger('Din adgangskode er ændret!');
                    $rootRouter.navigate(['Login']);
                }, function () {
                    ngToast.danger('Der skete en fejl');
                });
            } else {
                ngToast.danger('Husk at formularen');
            }
        }
    }
});