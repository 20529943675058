var angular = ﻿require('angular');
require('./api');

angular.module('dcRecipes').factory('Users', function ($q, Api) {
	function getUser() {
		return JSON.parse(window.localStorage.getItem('user'));
	}

	function logout() {
		window.localStorage.removeItem('user');
	}

	function login(email, password) {
		var deferred = $q.defer();
		Api.users.authenticate(email, password).then(function (res) {
			window.localStorage.setItem('user', JSON.stringify(res.data));
			deferred.resolve(res.data);
		}, function (res) {
			deferred.reject(res);
		});
		return deferred.promise;
    }

    function requestNewPass(email) {
        var deferred = $q.defer();
        Api.users.requestNewPass(email).then(function (res) {
            deferred.resolve(res.data);
        }, function (res) {
            deferred.reject(res);
        });
        return deferred.promise;
    }

    function resetPassword(password, token) {
        var deferred = $q.defer();
        Api.users.resetPassword(password, token).then(function (res) {
            deferred.resolve(res.data);
        }, function (res) {
            deferred.reject(res);
        });
        return deferred.promise;
    }

	return {
		getUser: getUser,
		logout: logout,
        login: login,
        requestNewPass: requestNewPass,
        resetPassword: resetPassword
	};
});
