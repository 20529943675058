var angular = require('angular');
require('./api');

angular.module('dcRecipes').factory('TagCategories', function ($q, Api) {
	return {
		get: function(id) {
			var deferred = $q.defer();
			Api.tagCategories.get(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		getAll: function () {
			var deferred = $q.defer();
			Api.tagCategories.getAll().then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		update: function (category) {
			var deferred = $q.defer();
			Api.tagCategories.update(category).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		create: function (category) {
			var deferred = $q.defer();
			Api.tagCategories.create(category).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		delete: function(id) {
			var deferred = $q.defer();
			Api.tagCategories.delete(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		}
	};
});
