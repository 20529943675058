var angular = ﻿require('angular');
require('../../services/users');
require('./list');
require('./new');
require('./edit');

angular.module('dcRecipes').component('recipes', {
	templateUrl: '/templates/recipes',
	$routeConfig: [
	  { path: '/', name: 'RecipeList', component: 'recipesList', useAsDefault: true },
	  { path: '/new', name: 'NewRecipe', component: 'recipesEdit' },
	  { path: '/edit/:id', name: 'EditRecipe', component: 'recipesEdit' }
	],
	controller: function (Users, $rootRouter) {
		this.$routerOnActivate = function () {
			if (!Users.getUser()) {
				$rootRouter.navigate(['Login']);
			}
		};
	}
});
