var angular = require('angular');

angular.module('dcRecipes').factory('Api', function ($http) {
	var apiBase = '/api/admin/';

	function token() {
		return window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')).token : null;
	}
	/*
	function userId() {
		return window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')).id : null;
	}*/

	return {
		users: {
			authenticate: function (email, password) {
				return $http({
					method: 'post',
					url: apiBase + 'users/authenticate',
					data: { email: email, password: password }
				});
			},
			getUserById: function (userId) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'users/' + userId
				});
            },
            requestNewPass: function (email) {
                return $http({
                    method: 'post',
                    url: apiBase + 'users/requestnewpassword?email='+email
                });
            },
            resetPassword: function (password, token) {
                return $http({
                    method: 'post',
                    url: apiBase + 'users/resetpassword',
                    data: { newPasssword: password, token: token }
                });
            }
		},
		recipes: {
			getAll: function (page, pageSize, sortField, sortAscending) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'recipes/?page=' + page + '&pageSize=' + pageSize + (sortField ? '&sortField=' + sortField : '') + '&sortAscending=' + (sortAscending ? 'true' : 'false')
				});
			},
			get: function (id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'recipes/' + id
				});
			},
			getSubrecipes: function (page, pageSize) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'recipes/subrecipes/?page=' + page + '&pageSize=' + pageSize
				});
			},
			query: function (query, ingredients, recipes, page, pageSize, sortField, sortAscending) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'recipes/?page=' + page + '&pageSize=' + pageSize + '&query=' + query + '&ingredientIds=' + ingredients + '&recipeIds=' + recipes + (sortField ? '&sortField=' + sortField : '') + '&sortAscending=' + (sortAscending ? 'true' : 'false')
				});
			},
			update: function (recipe, imageFile, logoFile) {
				var fd = new FormData();
                fd.append('recipe', angular.toJson(recipe));
                fd.append('image', imageFile);
                fd.append('logo', logoFile);
				return $http({
					headers: {
						'Authorization-Token': token(),
						'Content-Type': undefined
					},
					method: 'put',
					data: fd,
					url: apiBase + 'recipes/' + recipe.id
				});
			},
			create: function (recipe, imageFile, logoFile) {
				var fd = new FormData();
				fd.append('recipe', angular.toJson(recipe));
                fd.append('image', imageFile);
                fd.append('logo', logoFile);
				return $http({
					headers: {
						'Authorization-Token': token(),
						'Content-Type': undefined
					},
					method: 'post',
					data: fd,
					url: apiBase + 'recipes'
				});
			},
			delete: function(id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'delete',
					url: apiBase + 'recipes/' + id
				});
			}
		},
		abstractProducts: {
			get: function (id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'abstractproducts/' + id
				});
			},
			query: function (query, page, pageSize) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'abstractproducts/?page=' + page + '&pageSize=' + pageSize + '&query=' + query
				});
			},
			update: function (abstractProduct) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'put',
					data: abstractProduct,
					url: apiBase + 'abstractProducts/' + abstractProduct.id
				});
			},
			create: function (abstractProduct) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'post',
					data: abstractProduct,
					url: apiBase + 'abstractProducts'
				});
			},
			delete: function(id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'delete',
					url: apiBase + 'abstractproducts/' + id
				});
			}
		},
		tags: {
			getByCategoryId: function (id) {
		        return $http({
		            headers: {
		                'Authorization-Token': token()
		            },
		            method: 'get',
		            url: apiBase + 'tags/' + id
		        });
		    },
		    getAll: function () {
		        return $http({
		            headers: {
		                'Authorization-Token': token()
		            },
		            method: 'get',
		            url: apiBase + 'tags'
		        });
		    },
		    update: function (tag) {
		        return $http({
		            headers: {
		                'Authorization-Token': token()
		            },
		            method: 'put',
		            data: tag,
		            url: apiBase + 'tags/' + tag.id
		        });
		    },
		    create: function (tag) {
		        return $http({
		            headers: {
		                'Authorization-Token': token()
		            },
		            method: 'post',
		            data: tag,
		            url: apiBase + 'tags'
		        });
		    },
		    delete: function (id) {
		        return $http({
		            headers: {
		                'Authorization-Token': token()
		            },
		            method: 'delete',
		            url: apiBase + 'tags/' + id
		        });
		    }
		},
		tagCategories: {
			get: function (id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'tagCategories/' + id
				});
			},
			getAll: function () {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'get',
					url: apiBase + 'tagCategories'
				});
			},
			update: function (category) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'put',
					data: category,
					url: apiBase + 'tagCategories/' + category.id
				});
			},
			create: function (category) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'post',
					data: category,
					url: apiBase + 'tagCategories'
				});
			},
			delete: function (id) {
				return $http({
					headers: {
						'Authorization-Token': token()
					},
					method: 'delete',
					url: apiBase + 'tagCategories/' + id
				});
			}
		}
	};
});
