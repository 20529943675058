var angular = require('angular');
require('../modal');
require('../../services/recipes');
require('../../services/abstract-products');

angular.module('dcRecipes').component('recipesEdit', {
    templateUrl: '/templates/recipes/edit.html',
    controller: function (Recipes, AbstractProducts, $q, $sce, _, $rootRouter, ngToast, $window) {
        var $ctrl = this;

        $ctrl.results = [];
        $ctrl.query = null;
        $ctrl.queryRecipes = true;
        $ctrl.queryAbstractProducts = true;

        $ctrl.file = null;
        $ctrl.imageRefresh = null;
        $ctrl.newTag = null;

        $ctrl.trix = {
            editor: null
        };

        $ctrl.dialogs = {
            link: {
                active: false,
                valid: true,
                selectedRange: []
            },
            recipe: {
                active: false,
                valid: true
            }
        };

        $ctrl.deleteModal = {
            active: false,
            type: 'prompt',
            activate: function () {
                Recipes.query('', null, $ctrl.recipe.id, 1, 10000).then(function (queryResults) {
                    $ctrl.deleteModal.referencedBy = queryResults.totalResults;
                });
                $ctrl.deleteModal.active = true;
            },
            onConfirm: function () {
                Recipes.delete($ctrl.recipe.id).then(function () {
                    $rootRouter.navigate(['Recipes', 'RecipeList']);
                }, function () {
                    ngToast.danger('Der skete en fejl. Prøv igen senere.');
                });
            }
        };

        $ctrl.initTrix = function (initEvent, editor) {
            $ctrl.trix.editor = editor;
            //document.addEventListener('trix-action-invoke', function(actionEvent) {
            //	if(actionEvent.actionName == 'x-recipe-link') {
            //		$ctrl.recipeLink(actionEvent, editor);
            //  }
            //});
        };

        $ctrl.recipeLink = function () {
            if ($ctrl.recipeId) {
                Recipes.get($ctrl.recipeId).then(function (recipe) {
                    $ctrl.dialogs.recipe.valid = true;
                    $ctrl.trix.editor.insertHTML('<a href="/inspiration/opskrifter/' + recipe.urlName + '-' + recipe.id + '">' + recipe.name + '</a>')
                    $ctrl.recipeId = '';
                    $ctrl.dialogs.recipe.active = false;
                }, function (res) {
                    console.log(res);
                });
            } else {
                $ctrl.dialogs.recipe.valid = false;
            }
        };

        $ctrl.setNewVideo = function () {
            // check if vimeo or youtube

            $ctrl.recipe.video = $ctrl.newVideo;
            $ctrl.newVideo = null;
        };

        $ctrl.link = function () {
            if ($ctrl.dialogs.link.selectedRange.length > 0) {
                $ctrl.trix.editor.setSelectedRange([$ctrl.dialogs.link.selectedRange[0], $ctrl.dialogs.link.selectedRange[1]]);
                $ctrl.trix.editor.activateAttribute('href', $ctrl.url);
                $ctrl.url = '';
                $ctrl.dialogs.link.active = false;
            }
        };

        $ctrl.unlink = function () {
            if ($ctrl.dialogs.link.selectedRange.length > 0) {
                $ctrl.trix.editor.setSelectedRange([$ctrl.dialogs.link.selectedRange[0], $ctrl.dialogs.link.selectedRange[1]]);
                $ctrl.trix.editor.deactivateAttribute('href');
                $ctrl.dialogs.link.active = false;
            }
        };

        // Toggle custom editor stuff
        $ctrl.toggleLinkDialog = function () {
            if (!$ctrl.dialogs.link.active && $ctrl.trix.editor.getSelectedRange().length > 0) {
                $ctrl.dialogs.link.selectedRange = $ctrl.trix.editor.getSelectedRange()
            }
            $ctrl.dialogs.link.active = !$ctrl.dialogs.link.active;
            $ctrl.dialogs.recipe.active = false;
        }

        $ctrl.toggleRecipeDialog = function () {
            $ctrl.dialogs.recipe.active = !$ctrl.dialogs.recipe.active;
            $ctrl.dialogs.link.active = false;
        }

        $ctrl.closeDialogs = function () {
            $ctrl.dialogs.link.active = false;
            $ctrl.dialogs.recipe.active = false;
        };

        $ctrl.mealTypes = ["Forret", "Hovedret", "Dessert", "Morgenmad", "Frokost", "Mellemmåltid"];

        $ctrl.tagSuggestions = [
            "forret", "hovedret", "dessert", "kage", "morgenmad", "frokost", "mellemmåltid", "suppe", "smørrebrød", "sandwich", "fastfood", "børnetallerken",
            "okse", "kalv", "gris", "fjerkræ", "lam", "vildt", "fisk", "skaldyr", "grøntsag", "frugt", "mejeri",
            "tapas", "grønjanuar", "fransktema", "fisketema",
            "gkOkse", "gkKalv", "gkGris", "gkLam", "gkFjerkræ", "gkVildt", "gkFisk", "gkSkaldyr", "gkSuppe", "gkGryderet", "farsret", "pålæg", "kartofler", "ris pasta", "salat", "gkGrønt",
            "sauce dressing", "syltevarer", "brød", "gkDessert", "gkKage", "ost", "nytår"
        ];

        $ctrl.search = function () {
            var query = {};

            if ($ctrl.queryRecipes) {
                query.recipes = Recipes.query($ctrl.query, null, null, 1, 1000);
            }

            if ($ctrl.queryAbstractProducts) {
                query.abstractProducts = AbstractProducts.query($ctrl.query, 1, 1000);
            }

            $q.all(query).then(function (queryResults) {
                var results = [];
                if (queryResults.recipes) {
                    for (var r in queryResults.recipes.results) {
                        var recipe = queryResults.recipes.results[r];
                        results.push({
                            id: recipe.document.id,
                            name: recipe.document.name,
                            score: recipe.score,
                            type: 'Recipe'
                        });
                    }
                }

                if (queryResults.abstractProducts) {
                    for (var ap in queryResults.abstractProducts.results) {
                        var abstractProduct = queryResults.abstractProducts.results[ap];
                        results.push({
                            id: abstractProduct.document.id,
                            name: abstractProduct.document.name,
                            score: abstractProduct.score,
                            type: 'AbstractProduct'
                        });
                    }
                }

                $ctrl.results = results;
            });
        };

        $ctrl.addLabel = function () {
            $ctrl.recipe.ingredients.push({
                type: 'Label',
                sortOrder: $ctrl.recipe.ingredients.length,
                data: {
                    label: ''
                }
            });
        };

        $ctrl.addTag = function (tag) {
            var idx = $ctrl.recipe.tags.indexOf(tag);
            if (idx < 0) {
                $ctrl.recipe.tags.push(tag);
            }
            $ctrl.newTag = null;
        };

        $ctrl.removeTag = function (tag) {
            var idx = $ctrl.recipe.tags.indexOf(tag);
            if (idx > -1) {
                $ctrl.recipe.tags.splice(idx, 1);
            }
        };

        $ctrl.$routerOnActivate = function (next) {
            if (next.params.id) {
                Recipes.get(next.params.id).then(function (recipe) {
                    $ctrl.recipe = recipe;
                    $ctrl.recipe.ingredients = $ctrl.recipe.ingredients || [];
                    $ctrl.recipe.tags = _.filter(recipe.tags, function (tag) {
                        return tag !== '';
                    });

                    $ctrl.recipe.preparationTime ? $ctrl.recipe.preparationTime : 0;
                    $ctrl.recipe.cookingTime ? $ctrl.recipe.cookingTime : 0;
                });
            } else {
                $ctrl.recipe = {
                    id: 0,
                    name: null,
                    description: null,
                    instructions: null,
                    details: null,
                    plates: 1,
                    active: false,
                    hasImage: false,
                    hasLogo: false,
                    video: null,
                    tags: [],
                    ingredients: [],
                    visible: false,
                    mealType: null,
                    preparationTime: 0,
                    cookingTime: 0
                };
            }

            $window.scrollTo(0, 0);
        };

        $ctrl.incrementSortOrder = function (idx) {
            if (idx < $ctrl.recipe.ingredients.length - 1) {
                $ctrl.recipe.ingredients[idx].sortOrder++;
                $ctrl.recipe.ingredients[idx + 1].sortOrder--;
                $ctrl.sortIngredients();
            }
        };

        $ctrl.decrementSortOrder = function (idx) {
            if (idx > 0) {
                $ctrl.recipe.ingredients[idx].sortOrder--;
                $ctrl.recipe.ingredients[idx - 1].sortOrder++;
                $ctrl.sortIngredients();
            }
        };

        $ctrl.sortIngredients = function () {
            $ctrl.recipe.ingredients.sort(function (a, b) {
                return a.sortOrder - b.sortOrder;
            });
        };

        $ctrl.addIngredient = function (ingredient) {
            $ctrl.recipe.ingredients.push({
                id: ingredient.id,
                name: ingredient.name,
                sortOrder: $ctrl.recipe.ingredients.length,
                type: ingredient.type,
                weight: 0,
                converted: ingredient.converted ? true : false
            });
            $ctrl.sortIngredients();
        };

        $ctrl.deleteIngredient = function (idx) {
            $ctrl.recipe.ingredients.splice(idx, 1);
            $ctrl.sortIngredients();
        };

        $ctrl.removeImage = function () {
            $ctrl.recipe.hasImage = false;
            $ctrl.file = null;
        };

        $ctrl.removeLogo = function () {
            $ctrl.recipe.hasLogo = false;
            $ctrl.logo = null;
        };

        $ctrl.removeVideo = function () {
            $ctrl.recipe.video = '';
        };

        $ctrl.save = function () {
            if ($ctrl.file) {
                $ctrl.recipe.hasImage = true;
            }
            if ($ctrl.logo) {
                $ctrl.recipe.hasLogo = true;
            }

            if (!$ctrl.recipe.preparationTime) {
                $ctrl.recipe.preparationTime = 0;
            }
            if (!$ctrl.recipe.cookingTime) {
                $ctrl.recipe.cookingTime = 0;
            }
            if ($ctrl.recipe.id && $ctrl.recipe.id > 0) {
                Recipes.update($ctrl.recipe, $ctrl.file, $ctrl.logo).then(function () {
                    $ctrl.imageRefresh = Math.floor(Math.random() * 100000);
                    $ctrl.file = null;
                    $ctrl.logo = null;
                    ngToast.success('Opskriften blev gemt!');
                }, function () {
                    ngToast.danger("Der skete en fejl. Prøv igen senere.");
                });
            } else {
                Recipes.create($ctrl.recipe, $ctrl.file, $ctrl.logo).then(function (recipe) {
                    $rootRouter.navigate(['Recipes', 'EditRecipe', { id: recipe.id }]);
                }, function () {
                    ngToast.danger("Der skete en fejl. Prøv igen senere.");
                });
            }
        };
    }
}).directive('fileModel', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;

            element.bind('change', function () {
                scope.$apply(function () {
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]).filter('tagFilter', function (_) {
    return function (tags, search) {
        if (search && search.length > 0) {
            return _.filter(tags, function (tag) {
                return tag.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
        } else {
            return [];
        }
    };
    }).filter('videoDetector', function (_, $sce) {
    return function (url) {
        var videoType = _.includes(url, 'youtube') ? 'youtube' : _.includes(url, 'vimeo') ? 'vimeo' : null;
        var string = 'r';

        switch (videoType) {
            case 'youtube':
                string = 'wat';
                var id = url.split('v=')[1];
                string = 'https://youtube.com/embed/' + id;
                break;
            case 'vimeo':
                string = 'watwat';
                var slices = url.split('/');
                var idPosition = slices.length;
                string = 'https://player.vimeo.com/video/' + slices[idPosition - 1];
                break;
        }
        
        return $sce.trustAsResourceUrl(string);
    }
});
