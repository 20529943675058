var angular = require('angular');
require('./api');

angular.module('dcRecipes').factory('AbstractProducts', function ($q, Api) {
	//var self = this;
	return {
		get: function(id) {
			var deferred = $q.defer();
			Api.abstractProducts.get(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		query: function (query, page, pageSize) {
			page = page || 1;
			pageSize = pageSize || 10;

			var deferred = $q.defer();
			Api.abstractProducts.query(query, page, pageSize).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		update: function (abstractProduct) {
			var deferred = $q.defer();
			Api.abstractProducts.update(abstractProduct).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		create: function (abstractProduct) {
			var deferred = $q.defer();
			Api.abstractProducts.create(abstractProduct).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		delete: function(id) {
			var deferred = $q.defer();
			Api.abstractProducts.delete(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		}
	};
});
