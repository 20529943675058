var angular = require('angular');
require('../../services/abstract-products');
require('../../services/cache');

angular.module('dcRecipes').component('ingredientList', {
	templateUrl: '/templates/ingredients/list.html',
	controller: function (AbstractProducts, _, Cache, $window, $timeout) {
		var $ctrl = this;
		var cachedParams = Cache.ingredientSearch.getParams();

		$ctrl.page = cachedParams.page ? cachedParams.page : 1;
		$ctrl.query = cachedParams.query ? cachedParams.query : '';

		$ctrl.pageSize = 20;


		//$ctrl.recipes = [];
		$ctrl.totalResults = 0;

		$ctrl.abstractProducts = [];

		$ctrl.search = function() {
			return AbstractProducts.query($ctrl.query, $ctrl.page, $ctrl.pageSize).then(function(searchResult) {
				Cache.ingredientSearch.setParams($ctrl.query, $ctrl.page);
				$ctrl.abstractProducts = _.map(searchResult.results, function(result) {
					return result.document;
				});
				$ctrl.totalResults = searchResult.totalResults;
			});
		};

		$ctrl.nextPage = function() {
			$window.scrollTo(0, 0);
			Cache.ingredientSearch.setScrollHeight($window.scrollY);
			$ctrl.page++;
			$ctrl.search();
		};

		$ctrl.prevPage= function() {
			$window.scrollTo(0, 0);
			Cache.ingredientSearch.setScrollHeight($window.scrollY);
			$ctrl.page--;
			$ctrl.search();
		};

		$ctrl.totalPages = function() {
			return Math.ceil($ctrl.totalResults / $ctrl.pageSize);
		};

		$ctrl.onSubmitSearch = function() {
			$window.scrollTo(0, 0);
			Cache.ingredientSearch.setScrollHeight($window.scrollY);
			$ctrl.page = 1;
			$ctrl.search();
		};

		$ctrl.$routerOnActivate = function() {
			$ctrl.search().then(function() {
				$timeout(function () {
	          $window.scrollTo(0, Cache.ingredientSearch.getScrollHeight());
	      }, 250);
			});
		};
		$ctrl.$routerOnDeactivate = function() {
			Cache.ingredientSearch.setScrollHeight($window.scrollY);
		};
	}
});
