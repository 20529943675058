var angular = ﻿require('angular');
require('../../services/users');

angular.module('dcRecipes').component('loginSignIn', {
	templateUrl: '/templates/login/sign-in.html',
	controller: function (Users, $rootRouter, ngToast) {
		var ctrl = this;

		ctrl.user = {
			email: null,
			password: null
		};

		ctrl.login = function () {
			Users.login(ctrl.user.email, ctrl.user.password).then(function () {
				$rootRouter.navigate(['Recipes']);
			}, function () {
				ngToast.danger('Forkert login');
			});
		};
	}
});
