var angular = require('angular');
require('./sign-in');
require('./forgot-password');
require('./reset-password');

angular.module('dcRecipes').component('login', {
	templateUrl: '/templates/login',
	$routeConfig: [
	  { path: '/', name: 'SignIn', component: 'loginSignIn', useAsDefault: true },
	  { path: '/forgot-password', name: 'ForgotPassword', component: 'loginForgotPassword'},
	  { path: '/changepassword', name: 'ResetPassword', component: 'loginResetPassword' },
	],
	controller: function (Users, $rootRouter) {
		this.$routerOnActivate = function () {
		};
	}
});
