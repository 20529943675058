if (!String.prototype.startsWith) {
	String.prototype.startsWith = function (searchString, position) {
		position = position || 0;
		return this.indexOf(searchString, position) === position;
	};
}

var angular = require('angular');
require('angular-sanitize');
require('ng-toast');
require('trix');
require('angular-trix');

angular.module('dcRecipes', [require('ngcomponentrouter'), 'ngSanitize', 'ngToast', 'angularTrix'])
.config(function ($locationProvider, ngToastProvider) {
	$locationProvider.html5Mode(true);

	ngToastProvider.configure({
		verticalPosition: 'bottom'
	});
})
.factory('_', function() { return require('lodash'); })
.value('$routerRootComponent', 'app');

require('./components/ingredients');
require('./components/recipes');
require('./components/tags');
require('./components/login');
require('./services/users');
require('./services/lodash');

angular.module('dcRecipes').component('app', {
	templateUrl: '/templates/app.html',
	$routeConfig: [
	  { path: '/recipes/...', name: 'Recipes', component: 'recipes', useAsDefault: true },
	  { path: '/ingredients/...', name: 'Ingredients', component: 'ingredients' },
      { path: '/tags/...', name: 'Tags', component: 'tags' },
        { path: '/login/...', name: 'Login', component: 'login' }
	],
	controller: function (Users, $rootRouter, $scope) {
		var ctrl = this;

        ctrl.user = null;

		ctrl.logout = function () {
			Users.logout();
			$rootRouter.navigate(['Login']);
		};

		$scope.$watch(function () {
			return Users.getUser();
		}, function (newVal) {
			ctrl.user = newVal;
		}, true);
	}
});
