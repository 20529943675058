var angular = require('angular');
require('./api');

angular.module('dcRecipes').factory('Recipes', function ($q, Api) {
	var self = this;

	self.cachedParams = null;

	return {
		getAll: function (page, pageSize, sortField, sortAscending) {
			page = page || 1;
			pageSize = pageSize || 10;
			var deferred = $q.defer();
			Api.recipes.getAll(page, pageSize, sortField, sortAscending).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		get: function (id) {
			var deferred = $q.defer();
			Api.recipes.get(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		getSubrecipes: function (page, pageSize) {
			page = page || 1;
			pageSize = pageSize || 10;
			var deferred = $q.defer();
			Api.recipes.getSubrecipes(page, pageSize).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		query: function (query, ingredients, recipes, page, pageSize, sortField, sortAscending) {
			page = page || 1;
			pageSize = pageSize || 10;
			ingredients = ingredients || '';
			recipes = recipes || '';

			var deferred = $q.defer();
			Api.recipes.query(query, ingredients, recipes, page, pageSize, sortField, sortAscending).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
        update: function (recipe, imageFile, logoFile) {
			var deferred = $q.defer();
            Api.recipes.update(recipe, imageFile, logoFile).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
        create: function (recipe, imageFile, logoFile) {
			var deferred = $q.defer();
            Api.recipes.create(recipe, imageFile, logoFile).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		},
		delete: function (id) {
			var deferred = $q.defer();
			Api.recipes.delete(id).then(function (res) {
				deferred.resolve(res.data);
			}, function (res) {
				deferred.reject(res);
			});
			return deferred.promise;
		}
	};
});
