var angular = require('angular');

angular.module('dcRecipes').component('modal', {
	templateUrl: '/templates/modal.html',
	transclude: true,
	bindings: {
		options: '='
	},
	controller: function () {
		var $ctrl = this;

		$ctrl.confirm = function () {
			$ctrl.options.active = false;
			if($ctrl.options && $ctrl.options.onConfirm) {
				$ctrl.options.onConfirm();
			}
		};

		$ctrl.cancel = function () {
			$ctrl.options.active = false;
			if($ctrl.options && $ctrl.options.onCancel) {
				$ctrl.options.onCancel();
			}
		};
	}
});
