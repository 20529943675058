require('angular');
require('../../services/users');
require('./list');
require('./new');
require('./edit');

angular.module('dcRecipes').component('ingredients', {
	templateUrl: '/templates/ingredients',
	$routeConfig: [
	  { path: '/', name: 'IngredientList', component: 'ingredientList', useAsDefault: true },
	  { path: '/new', name: 'NewIngredient', component: 'ingredientEdit' },
	  { path: '/edit/:id', name: 'EditIngredient', component: 'ingredientEdit' }
	],
	controller: function (Users, $rootRouter) {
		this.$routerOnActivate = function () {
			if (!Users.getUser()) {
				$rootRouter.navigate(['Login']);
			}
		};
	}
});
