var angular = require('angular');
require('../modal');
require('../../services/abstract-products');

angular.module('dcRecipes').component('ingredientEdit', {
	templateUrl: '/templates/ingredients/edit.html',
	controller: function (AbstractProducts, Recipes, $rootRouter, ngToast) {
		var $ctrl = this;
		$ctrl.newTag = null;

		$ctrl.$routerOnActivate = function (next) {
			if(next.params.id) {
				AbstractProducts.get(next.params.id).then(function (abstractProduct) {
					$ctrl.ingredient = abstractProduct;
					$ctrl.ingredient.tags = $ctrl.ingredient.tags || [];
				});
			} else {
				$ctrl.ingredient = {
				  id: null,
				  name: null,
				  visible: false,
				  tags: [],
				  nutrition: {
				    fat: null,
				    carbohydrate: null,
				    sugarTypes: null,
				    fibers: null,
				    protein: null,
				    salt:null,
				    saturatedFat: null
				  },
				  loss: null,
				  density: null,
				  weightForOne: null
				};
			}
		};

		$ctrl.deleteModal = {
			active: false,
			type: 'prompt',
			data: {
				referencedBy: null
			},
			activate: function() {
				Recipes.query('', $ctrl.ingredient.id, 1, 10000).then(function(queryResults) {
					$ctrl.deleteModal.referencedBy = queryResults.totalResults;
				});
				$ctrl.deleteModal.active = true;
			},
			onCancel: function() {

			},
			onConfirm: function () {
				AbstractProducts.delete($ctrl.ingredient.id).then(function() {
						$rootRouter.navigate(['Ingredients','IngredientList']);
				},function() {
					ngToast.danger("Der skete en fejl. Prøv igen senere.");
				});
			}
		};

		$ctrl.addTag = function (tag) {
			var idx = $ctrl.ingredient.tags.indexOf(tag);
			if (idx < 0) {
				$ctrl.ingredient.tags.push(tag);
			}
			$ctrl.newTag = null;
		};

		$ctrl.removeTag = function (tag) {
			var idx = $ctrl.ingredient.tags.indexOf(tag);
			if (idx > -1) {
				$ctrl.ingredient.tags.splice(idx, 1);
			}
		};

		$ctrl.save = function () {
			if($ctrl.ingredient.id && $ctrl.ingredient.id > 0) {
				AbstractProducts.update($ctrl.ingredient).then(function (ingredient) {
					$ctrl.ingredient = ingredient;
					ngToast.success("Ingrediensen blev gemt!");
				},function() {
					ngToast.danger("Der skete en fejl. Prøv igen senere.");
				});
			} else {
				AbstractProducts.create($ctrl.ingredient).then(function (ingredient) {
					$rootRouter.navigate(['Ingredients', 'EditIngredient', { id: ingredient.id }]);
				}, function() {
					ngToast.danger("Der skete en fejl. Prøv igen senere.");
				});
			}
		};
  }
});
