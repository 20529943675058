var angular = require('angular');

angular.module('dcRecipes').factory('Cache', function () {
    var self = this;

    self.recipeSearch = (function () {
        var params = {
            query: null,
            selectedProduct: null,
            selectedSubrecipe: null,
            page: null,
            sortField: null,
			sortAscending: false
        };

        var scrollHeight = 0;

        return {
            setParams: function (query, selectedProduct, selectedSubrecipe, page, sortField, sortAscending) {
                params.query = query ? query : null;
                params.selectedProduct = selectedProduct ? selectedProduct : null;
                params.selectedSubrecipe = selectedSubrecipe ? selectedSubrecipe : null;
                params.page = page ? page : null;
                params.sortField = sortField ? sortField : null;
				params.sortAscending = sortAscending ? true : false;
            },
            getParams: function () {
                return params;
            },
            setScrollHeight: function (scrollY) {
                scrollHeight = scrollY;
            },
            getScrollHeight: function () {
                return scrollHeight;
            }
        };
    })();

    self.ingredientSearch = (function () {
        var params = {
            query: null,
            page: null
        };

        var scrollHeight = 0;

        return {
            setParams: function (query, page) {
                params.query = query ? query : null;
                params.page = page ? page : null;
            },
            getParams: function () {
                return params;
            },
            setScrollHeight: function (scrollY) {
                scrollHeight = scrollY;
            },
            getScrollHeight: function () {
                return scrollHeight;
            }
        };
    })();

    self.tagsSearch = (function () {
        var params = {
            query: null,
            page: null
        };

        var scrollHeight = 0;

        return {
            setParams: function (query, page) {
                params.query = query ? query : null;
                params.page = page ? page : null;
            },
            getParams: function () {
                return params;
            },
            setScrollHeight: function (scrollY) {
                scrollHeight = scrollY;
            },
            getScrollHeight: function () {
                return scrollHeight;
            }
        };
    })();

    return self;
});
