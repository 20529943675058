var angular = require('angular');
require('../modal');
require('../../services/tags');
require('../../services/tag-categories');

angular.module('dcRecipes').component('tagsEdit', {
	templateUrl: '/templates/tags/edit.html',
	controller: function (Tags, TagCategories, $rootRouter, ngToast, _, $q) {
		var $ctrl = this;

		$ctrl.category = null;
		$ctrl.tags = [];

		

		$ctrl.addTag = function () {
			$ctrl.tags.push({
				id: null,
				name: '',
				tag: '',
				categoryId: $ctrl.category.id
			});
		};

		$ctrl.deleteTag = function (tag) {
			var idx = _.findLastIndex($ctrl.tags, { id: tag.id, name: tag.name, tag: tag.tag, categoryId: tag.categoryId });

			if (idx > -1) {
				if (tag.id) {
					tag.deleted = true;
				} else {
					$ctrl.tags.splice(idx, 1);
				}
			}
		};
		

		$ctrl.$routerOnActivate = function (next) {
			if (next.params.id) {
				TagCategories.get(next.params.id).then(function (category) {
					$ctrl.category = category;
				});

			    Tags.getByCategoryId(next.params.id).then(function (tags) {
			    	$ctrl.tags = tags
				});
			} else {
				$ctrl.category = {
					id: null,
					name: null
				};

				$ctrl.tags = [];
			}
		};

		$ctrl.deleteModal = {
			active: false,
			type: 'prompt',
			activate: function () {
				$ctrl.deleteModal.active = true;
			},
			onConfirm: function () {
				TagCategories.delete($ctrl.category.id).then(function () {
					$rootRouter.navigate(['Tags', 'TagsList']);
				}, function () {
					ngToast.danger('Der skete en fejl. Prøv igen senere.');
				});
			}
		};

		$ctrl.save = function () {
			if ($ctrl.category.id) {
				TagCategories.update($ctrl.category).then(function (category) {
					$ctrl.category = category;

					var tagPromises = [];

					for (var t in $ctrl.tags) {
						var tag = $ctrl.tags[t];

						if (tag.id) {
							if (tag.deleted) {
								tagPromises.push(Tags.delete(tag.id));
							} else {
								tagPromises.push(Tags.update(tag));
							}
						} else {
							tagPromises.push(Tags.create(tag));
						}
					}

					$q.all(tagPromises).then(function () {
						Tags.getByCategoryId($ctrl.category.id).then(function (tags) {
							$ctrl.tags = tags
							ngToast.success('Kategorien blev gemt!');
						});
					});
				});
			} else {
				TagCategories.create($ctrl.category).then(function (category) {
					$rootRouter.navigate(['Tags', 'EditTag', { id: category.id }]);
				}, function () {
					ngToast.danger("Der skete en fejl. Prøv igen senere.");
				});
			}
		};
  }
});
